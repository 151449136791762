<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { AvatarRoot } from 'radix-vue';
import { type AvatarVariants, avatarVariant } from '.';
import { cn } from '@/lib/utils';

const props = withDefaults(
    defineProps<{
        class?: HTMLAttributes['class'];
        size?: AvatarVariants['size'];
        shape?: AvatarVariants['shape'];
    }>(),
    {
        size: 'sm',
        shape: 'circle',
        class: {},
    },
);
</script>

<template>
    <AvatarRoot :class="cn(avatarVariant({ size, shape }), props.class)">
        <slot />
    </AvatarRoot>
</template>
